<template>
	<div class="psi-inventorydetail">
		<div class="my-title clearfix">
			<span class="">库存明细</span>
		</div>
		<el-table :data="list" stripe>
			<el-table-column prop="ordertime" label="日期"></el-table-column>
			<el-table-column prop="type" label="类型"  align="center">
				<template slot-scope="scope">
					{{ scope.row.type == 1 ? '进货单' : '销售单' }}
				</template>
			</el-table-column>
			<el-table-column prop="customer" label="单位" align="center"></el-table-column>
			<el-table-column prop="num" label="数量" align="center">
				<template slot-scope="scope">
					{{ scope.row.type == 1 ? scope.row.num : -1*scope.row.num }}
				</template>
			</el-table-column>
		</el-table>
		<!-- 分页 -->
		<div class="paging">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" background
				:current-page.sync="currentPage" :page-size="pagesize" layout="prev, pager, next, jumper" :total="total">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import * as Api from '@/api/psi'
	export default {
		data() {
			return {
				currentPage: 1,
				pagesize: 20,
				total: 0,
				goods_id: 0,
				list: [],
			}
		},
		watch:{
			$route(){
				this.init()
			}
		},
		created() {
			this.init()
		},
		methods: {
			init(){
				if(this.$route.query.goods_id){
					this.goods_id = this.$route.query.goods_id
				}
				this.getPageData()
			},
			
			// 获取页面数据
			getPageData() {
			  const app = this
			  Promise.all([app.getList()])
			    .then(() => {
			    })
			},
			
			// 获取列表
			getList() {
			  const app = this
			  return new Promise((resolve, reject) => {
			    Api.inventorydetail_list({ page: app.currentPage, pagesize: app.pagesize, goods_id: this.goods_id })
			      .then(result => {
					  console.log(result)
			        app.list = result.data.res.list
					app.total = result.data.res.total
			        resolve(result)
			      })
			      .catch(err => reject(err))
			  })
			},
			
			handleSizeChange(val) {
				console.log(`每页 ${val} 条`);
			},
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.currentPage = val
				this.getPageData()
			},
			handleClick(tab, event) {
				console.log(tab, event);
			}
		}
	}
</script>

<style lang="scss">
</style>
