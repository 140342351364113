<template>
	<div class="address-add">
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
			<el-form-item label="商品分类" prop="category_id">
				<el-select v-model="ruleForm.category_id" placeholder="请选择商品分类">
				  <el-option
				    v-for="item in categoryList"
				    :key="item.id"
				    :label="item.name"
				    :value="item.id">
				  </el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="商品编码" prop="goods_no">
				<el-input v-model="ruleForm.goods_no" placeholder="请输入商品编码"></el-input>
			</el-form-item>
			<el-form-item label="商品名称" prop="goods_name">
				<el-input v-model="ruleForm.goods_name" placeholder="请输入商品名称"></el-input>
			</el-form-item>
			<el-form-item label="规格" prop="spec">
				<el-input v-model="ruleForm.spec" placeholder="请输入规格"></el-input>
			</el-form-item>

			<el-form-item>
				<el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
				<el-button @click="resetForm('ruleForm')">重置</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
	import * as Api from '@/api/psi'
	export default {
		props:{
			goods: Object
		},
		data() {
			return {
				ruleForm: {
					id: 0,
					category_id: '',
					goods_no: '',
					goods_name: '',
					spec: ''
				},
				rules: {
					category_id: [{
							required: true,
							message: '请选择商品分类',
							trigger: 'change'
						}
					],
					goods_no: [{
							required: true,
							message: '请输入商品编码',
							trigger: 'blur'
						}
					],
					goods_name: [{
							required: true,
							message: '请输入商品名称',
							trigger: 'blur'
						}
					],
				},
				categoryList: []
			};
		},
		watch:{
			goods(){
				this.initData()
			}
		},
		created() {
			this.getCategoryList()
			this.initData()
		},
		methods: {
			initData(){
				this.ruleForm = this.goods
				if(!this.ruleForm.id){
					this.ruleForm.id = 0
				}
				this.$forceUpdate()
			},
			getCategoryList(){
				let app = this
				Api.category_list().then(result => {
					app.categoryList = result.data.list
				})
			},
			submitForm(formName) {
				let app = this
				this.$refs[formName].validate((valid) => {
					if (valid) {
						console.log(app.ruleForm)
						Api.goods_save(app.ruleForm)
						  .then(result => {
						    app.$message.success(result.message)
							app.$emit('update')
						  })
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			}
		}
	}
</script>

<style>
</style>
