<template>
	<div class="psi-category">
		<div class="my-title clearfix">
			<span class="">商品分类</span>
			<em @click="addClick()">添加新分类</em>
		</div>
		<el-table :data="list" stripe>
			<el-table-column prop="name" label="分类名称">
			</el-table-column>
			<el-table-column label="操作" width="200" align="center">
				<template slot-scope="scope">
					<el-button class="button-primary-mini" type="primary" size="mini" @click="editClick(scope.row)">编辑</el-button>
					<el-button class="button-warning-mini" type="danger" size="mini" @click="handleRemove(scope.row.id)">删除</el-button>
				</template>
			</el-table-column>
		</el-table>
		<!-- 添加新分类 -->
		<el-dialog :title="title" :visible.sync="dialogVisible" width="30%">
			<categoryAdd :category="category" @update="update"></categoryAdd>
		</el-dialog>
	</div>
</template>

<script>
	import * as Api from '@/api/psi'
	import categoryAdd from "./components/category.vue"
	export default {
		components:{categoryAdd},
		data() {
			return {
				category: {},
				title: '',
				dialogVisible: false,
				list: [],
			}
		},
		created() {
			this.getPageData()
		},
		methods: {
			editClick(item){
				this.title = '编辑商品分类'
				this.category = item
				this.dialogVisible = true
			},
			update(){
				this.getPageData()
				this.dialogVisible = false
			},
			// 获取页面数据
			getPageData() {
			  const app = this
			  Promise.all([app.getList()])
			    .then(() => {
			      this.dialogVisible = false
			    })
			},

			// 获取商品分类列表
			getList() {
			  const app = this
			  return new Promise((resolve, reject) => {
			    Api.category_list()
			      .then(result => {
					  console.log(result)
			        app.list = result.data.list
			        resolve(result)
			      })
			      .catch(err => reject(err))
			  })
			},

			addClick() {
				this.title = '新增商品分类'
				this.category = {}
				this.dialogVisible = true
			},

			handleRemove(categoryId) {
			  const app = this
			  this.$confirm('您确定要删除当前记录吗?', '提示', {
			  	confirmButtonText: '确定',
			  	cancelButtonText: '取消',
			  	type: 'warning'
			  }).then(() => {
			  	app.onRemove(categoryId)
			  }).catch(() => {});
			},

			onRemove(categoryId) {
			  const app = this
			  Api.category_del({id: categoryId})
			    .then(result => {
			      app.getPageData()
			    })
			},
		}
	}
</script>

<style lang="scss">
</style>
