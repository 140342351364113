<template>
	<div class="psi-customer">
		<div class="my-title clearfix">
			<span class="">{{type_name}}管理</span>
			<em @click="addClick()">添加新{{type_name}}</em>
		</div>
		<el-table :data="list" stripe>
			<el-table-column prop="name" label="名称">
			</el-table-column>
			<el-table-column prop="phone" label="电话" align="center">
			</el-table-column>
			<el-table-column prop="contact" label="联系人" align="center">
			</el-table-column>
			<el-table-column prop="address" label="地址" align="center">
			</el-table-column>
			<el-table-column label="操作" width="200" align="center">
				<template slot-scope="scope">
					<el-button class="button-primary-mini" type="primary" size="mini" @click="editClick(scope.row)">编辑</el-button>
					<el-button class="button-warning-mini" type="danger" size="mini" @click="handleRemove(scope.row.id)">删除</el-button>
				</template>
			</el-table-column>
		</el-table>
		<!-- 分页 -->
		<div class="paging">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" background
				:current-page.sync="currentPage" :page-size="pagesize" layout="prev, pager, next, jumper" :total="total">
			</el-pagination>
		</div>
		<!-- 添加 -->
		<el-dialog :title="title" :visible.sync="dialogVisible" width="30%">
			<customerAdd :customer="customer" @update="update"></customerAdd>
		</el-dialog>
	</div>
</template>

<script>
	import * as Api from '@/api/psi'
	import customerAdd from "./components/customer.vue"
	export default {
		components:{customerAdd},
		data() {
			return {
				type: 0,
				type_name: '',
				currentPage: 1,
				pagesize: 20,
				total: 0,
				customer: {},
				title: '',
				dialogVisible: false,
				list: [],
			}
		},
		watch: {
		  '$route':'init'
		},
		created() {
			this.init()
		},
		methods: {
			init(){
				if(this.$route.query.type){
					this.type = this.$route.query.type
				}
				if(this.type == 1){
					this.type_name = '供应商'
				}else{
					this.type_name = '客户'
				}
				this.getPageData()
			},
			editClick(item){
				this.title = '编辑' + this.type_name
				this.customer = item
				this.dialogVisible = true
			},
			update(){
				this.getPageData()
				this.dialogVisible = false
			},
			// 获取页面数据
			getPageData() {
			  const app = this
			  Promise.all([app.getList()])
			    .then(() => {
			      this.dialogVisible = false
			    })
			},

			// 获取列表
			getList() {
			  const app = this
			  return new Promise((resolve, reject) => {
			    Api.customer_list({ page: app.currentPage, pagesize: app.pagesize, type: app.type })
			      .then(result => {
					  console.log(result)
			        app.list = result.data.list.data
					app.total = result.data.list.total
			        resolve(result)
			      })
			      .catch(err => reject(err))
			  })
			},

			addClick() {
				this.title = '新增'+this.type_name
				this.customer = {
					type: this.type
				}
				this.dialogVisible = true
			},

			handleRemove(id) {
			  const app = this
			  this.$confirm('您确定要删除当前记录吗?', '提示', {
			  	confirmButtonText: '确定',
			  	cancelButtonText: '取消',
			  	type: 'warning'
			  }).then(() => {
			  	app.onRemove(id)
			  }).catch(() => {});
			},

			onRemove(id) {
			  const app = this
			  Api.customer_del({id: id})
			    .then(result => {
			      app.getPageData()
			    })
			},

			handleSizeChange(val) {
				console.log(`每页 ${val} 条`);
			},
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.currentPage = val
				this.getPageData()
			},
			handleClick(tab, event) {
				console.log(tab, event);
			}
		}
	}
</script>

<style lang="scss">
</style>
