<template>
	<div class="psi-inventory">
		<div class="my-title clearfix">
			<span class="">商品库存</span>
			<em style="margin-left: 10px;" @click="handleDownload()">导出Excel</em>
		</div>

		<el-form :inline="true" :model="formInline" class="demo-form-inline">
			<el-form-item label="商品">
				<el-cascader filterable clearable :options="goodsList" :show-all-levels="false"
					@change="goodsChange"></el-cascader>
			</el-form-item>
			<el-form-item>

			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="onSearch">查询</el-button>
			</el-form-item>
		</el-form>

		<el-table :data="list" stripe>
			<el-table-column prop="goods_no" label="商品编码"></el-table-column>
			<el-table-column prop="goods_name" label="商品名称" align="center"></el-table-column>
			<el-table-column prop="category.name" label="商品分类" align="center"></el-table-column>
			<el-table-column prop="spec" label="规格" align="center"></el-table-column>
			<el-table-column prop="num" label="库存数量" align="center">
				<template slot-scope="scope">
					{{ scope.row.num ? scope.row.num : 0 }}
				</template>
			</el-table-column>
			<el-table-column label="操作" width="200" align="center">
				<template slot-scope="scope">
					<el-button class="button-primary-mini" type="primary" size="mini" @click="detailClick(scope.row)">查看明细</el-button>
				</template>
			</el-table-column>
		</el-table>
		<!-- 分页 -->
		<div class="paging">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" background
				:current-page.sync="currentPage" :page-size="pagesize" layout="prev, pager, next, jumper" :total="total">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import * as Api from '@/api/psi'

	export default {
		data() {
			return {
				currentPage: 1,
				pagesize: 20,
				total: 0,
				list: [],
				search: {
					goods_id: '0'
				},
				goodsList: []
			}
		},
		created() {
			this.getPageData()
			this.getGoodsList()
		},
		methods: {
			onSearch() {
				this.currentPage = 1
				this.getPageData()
			},
			// 获取页面数据
			getPageData() {
				const app = this
				Promise.all([app.getList()])
					.then(() => {})
			},

			getGoodsList() {
				const app = this
				Api.goodstree_list()
					.then(result => {
						let list = result.data.list
						let goodsList = []
						list.forEach(e => {
							let children = []
							e.goods.forEach(c => {
								children.push({
									value: c.id,
									label: c.goods_name + '[' + c.goods_no + ']'
								})
							})
							goodsList.push({
								value: e.id,
								label: e.name,
								children: children
							})
						})
						app.goodsList = goodsList
					})
			},

			// 获取列表
			getList() {
				const app = this
				return new Promise((resolve, reject) => {
					Api.inventory_list({
							page: app.currentPage,
							pagesize: app.pagesize,
							goods_id: app.search.goods_id
						})
						.then(result => {
							console.log(result)
							app.list = result.data.res.list
							app.total = result.data.res.total
							resolve(result)
						})
						.catch(err => reject(err))
				})
			},

			handleSizeChange(val) {
				console.log(`每页 ${val} 条`);
			},
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.currentPage = val
				this.getPageData()
			},
			handleClick(tab, event) {
				console.log(tab, event);
			},
			detailClick(item) {
				this.$router.push('/myhome/psi-inventorydetail?goods_id=' + item.id)
			},
			handleDownload() {
				let app = this

				let filename = '商品库存'

				import('@/vendor/Export2Excel').then(excel => {
					const tHeader = ['商品编码', '商品名称', '分类', '规格', '库存数量']
					const filterVal = ['goods_no', 'goods_name', 'category_name', 'spec', 'num']
					const data = this.formatJson(filterVal, app.list)
					excel.export_json_to_excel({
						header: tHeader,
						data,
						filename: filename,
						autoWidth: true,
						bookType: 'xlsx'
					})
				})
			},
			formatJson(filterVal, jsonData) {
				let _this = this
				return jsonData.map(v => filterVal.map(j => {
					console.log()
					if (j === 'num') {
						if (v[j]) {
							return v.[j]
						} else {
							return 0
						}
					}
					return v[j]
				}))
			},
			goodsChange(e) {
				console.log(e)
				if (e && e.length > 1) {
					this.search.goods_id = e[1]
				}
			}
		}
	}
</script>

<style lang="scss">
</style>