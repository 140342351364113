<template>
	<div class="address-add">
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
			<el-form-item label="名称" prop="name">
				<el-input v-model="ruleForm.name" placeholder="请输入商品分类名称"></el-input>
			</el-form-item>

			<el-form-item>
				<el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
				<el-button @click="resetForm('ruleForm')">重置</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
	import * as Api from '@/api/psi'
	export default {
		props:{
			category: Object
		},
		data() {
			return {
				ruleForm: {
					id: 0,
					name: '',
				},
				rules: {
					name: [{
							required: true,
							message: '请输入商品分类名称',
							trigger: 'blur'
						}
					],
				}
			};
		},
		watch:{
			category(){
				this.initData()
			}
		},
		created() {
			this.initData()
		},
		methods: {
			initData(){
				this.ruleForm = this.category
				if(!this.ruleForm.id){
					this.ruleForm.id = 0
				}
				this.$forceUpdate()
			},
			submitForm(formName) {
				let app = this
				this.$refs[formName].validate((valid) => {
					if (valid) {
						console.log(app.ruleForm)
						Api.category_save(app.ruleForm)
						  .then(result => {
						    app.$message.success(result.message)
							app.$emit('update')
						  })
					} else {
						console.log('error submit!!');
                        app.$emit('update')
						return false;
					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			}
		}
	}
</script>

<style>
</style>
