<template>
	<div class="psi-order">
		<div class="my-title clearfix">
			<span class="">{{type_name}}管理</span>
			<em style="margin-left: 10px;" @click="handleDownload()">导出Excel</em>
			<em @click="addClick()">新建{{type_name}}</em>
		</div>

		<el-form :inline="true" :model="search" class="demo-form-inline">
		  <el-form-item :label="type == 1 ? '供应商' : '客户'">
		    <el-select clearable v-model="search.customer_id" placeholder="">
		      <el-option v-for="(item,index) in customerList" :key="index" :label="item.name" :value="item.id"></el-option>
		    </el-select>
		  </el-form-item>
		  <el-form-item label="开始日期">
		    <el-date-picker
		      v-model="search.start_date"
		      type="date"
		      value-format="yyyy-MM-dd"
		      placeholder="请选择开始日期">
		    </el-date-picker>
		  </el-form-item>
		  <el-form-item label="结束日期">
		    <el-date-picker
		      v-model="search.end_date"
		      type="date"
		      value-format="yyyy-MM-dd"
		      placeholder="请选择结束日期">
		    </el-date-picker>
		  </el-form-item>
		  <el-form-item>
		    <el-button type="primary" @click="onSearch">查询</el-button>
		  </el-form-item>
		</el-form>
		<el-table :data="list.data" stripe>
			<el-table-column prop="ordertime" label="时间" width="110"></el-table-column>
			<el-table-column prop="order_no" label="单号" width="165" align="center"></el-table-column>
			<el-table-column v-if="type == 1" prop="customer.name" label="供应商" align="center"></el-table-column>
			<el-table-column v-if="type == 2" prop="customer.name" label="客户" align="center"></el-table-column>
			<el-table-column prop="amount" label="金额" width="80" align="center"></el-table-column>
			<el-table-column prop="remark" label="备注" align="center"></el-table-column>
			<el-table-column label="操作" width="210" align="center">
				<template slot-scope="scope">
					<el-button class="button-primary-mini" type="primary" @click="downloadDetails(scope.row)">导出明细</el-button>
					<el-button class="button-primary-mini" type="primary" size="mini" @click="editClick(scope.row)">编辑</el-button>
					<el-button class="button-warning-mini" type="danger" size="mini" @click="handleRemove(scope.row.id)">删除</el-button>
				</template>
			</el-table-column>
		</el-table>
		<!-- 分页 -->
		<div class="paging">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" background
				:current-page.sync="currentPage" :page-size="pagesize" layout="prev, pager, next, jumper" :total="total">
			</el-pagination>
		</div>
		<!-- 添加 -->
		<el-dialog :title="title" :visible.sync="dialogVisible" width="80%">
			<orderAdd :order="order" @update="update"></orderAdd>
		</el-dialog>
	</div>
</template>

<script>
	import * as Api from '@/api/psi'
	import orderAdd from "./components/order.vue"
	export default {
		components:{orderAdd},
		data() {
			return {
				type: 0,
				type_name: '',
				currentPage: 1,
				pagesize: 10,
				total: 0,
				order: {},
				title: '',
				dialogVisible: false,
				list: [],
				search: {
					customer_id: '',
					start_date: '',
					end_date: ''
				},
				customerList: []
			}
		},
        watch: {
            '$route': 'init'
        },
		created() {
			this.init()
		},
		methods: {
			onSearch(){
				this.currentPage = 1
				this.getPageData()
			},
			init(){
				if(this.$route.query.type){
					this.type = this.$route.query.type
				}
				if(this.type == 1){
					this.type_name = '进货单'
				}else{
					this.type_name = '销售单'
				}
				this.search.customer_id = ''
				this.search.start_date = ''
				this.search.end_date = ''
				this.currentPage = 1
				this.getPageData()
				this.getCustomerList()
			},
			editClick(item){
				this.title = '编辑' + this.type_name
				this.order = item
				this.dialogVisible = true
			},
			update(){
				this.getPageData()
				this.dialogVisible = false
			},
			// 获取页面数据
            getPageData() {
                const app = this
                Promise.all([app.getList()])
                    .then(() => {
                        this.dialogVisible = false
                    })
            },
            getCustomerList() {
                Api.customer_list({type: this.type, page: 1, pagesize: 9999})
                    .then(result => {
                        this.customerList = result.data.list.data
                    })
            },
            // 获取列表
            getList() {
                const app = this
                return new Promise((resolve, reject) => {
                    Api.order_list({
                        page: app.currentPage,
                        type: app.type,
                        customer_id: app.search.customer_id,
                        start_date: app.search.start_date,
                        end_date: app.search.end_date
                    })
                        .then(result => {
                            console.log(result)
                            app.list = result.data.list
                            app.total = result.data.list.total
                            resolve(result)
                        })
                        .catch(err => reject(err))
                })
            },

            addClick() {
                this.title = '新增' + this.type_name
                this.order = {
                    type: this.type,
                    details: []
                }
                this.dialogVisible = true
            },

            handleRemove(id) {
                const app = this
                this.$confirm('您确定要删除当前记录吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    app.onRemove(id)
                }).catch(() => {
                });
            },

            onRemove(id) {
                const app = this
                Api.order_del({id: id})
                    .then(result => {
                        app.getPageData()
                    })
            },

			handleSizeChange(val) {
				console.log(`每页 ${val} 条`);
			},
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.currentPage = val
				this.getPageData()
			},
			handleClick(tab, event) {
				console.log(tab, event);
			},
            handleDownload() {
                let app = this
                let filename = (app.type == 1 ? '进货单' : '销售单')
                import('@/vendor/Export2Excel').then(excel => {
                    const tHeader = ['订单日期', '订单号', app.type == 1 ? '供应商' : '客户', '金额', '备注']
                    const filterVal = ['ordertime', 'order_no', 'customer', 'amount', 'remark']
                    const data = this.formatJson(filterVal, app.list.data)
                    excel.export_json_to_excel({
                        header: tHeader,
                        data,
                        filename: filename,
                        autoWidth: true,
                        bookType: 'xlsx'
                    })
                })
            },
            formatJson(filterVal, jsonData) {
                return jsonData.map(v => filterVal.map(j => {
                    console.log()
                    if (j === 'customer' && v[j]) {
                        return v['customer']['name']
                    }
                    return v[j]
                }))
            },

            downloadDetails(order) {
                let app = this
                let filename = (app.type == 1 ? '进货单' : '销售单') + '-' + order.order_no
                import('@/vendor/Export2Excel').then(excel => {
                    const tHeader = ['商品编码', '商品名称', '价格', '数量', '金额']
                    const filterVal = ['goods_no', 'goods_name', 'price', 'num', 'amount']
                    const data = this.formatDetailJson(filterVal, order.details)
                    excel.export_json_to_excel({
                        header: tHeader,
                        data,
                        filename: filename,
                        autoWidth: true,
                        bookType: 'xlsx'
                    })
                })
            },
            formatDetailJson(filterVal, jsonData) {
                return jsonData.map(v => filterVal.map(j => {
                    if (j == 'goods_name') {
                        return v['goods']['goods_name']
                    }
                    if (j == 'goods_no') {
                        return v['goods']['goods_no']
                    }
                    return v[j]
                }))
            },
		}
	}
</script>

<style lang="scss">
</style>
