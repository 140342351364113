import request from '@/utils/request'

// api地址
const api = {
    list: 'faw/list',
    add: 'faw/add',
    expressDetail: 'faw/express_detail',
    tdsList: 'faw/tds_list',
    subinventory: 'faw/sub_inventory_detail',
    updateOrderExpress: 'faw/updateOrderExpress',
}

// 收货地址列表
export const list = (param) => {
    return request({
        url: api.list,
        method: 'get',
        params: param,
    })
}

export const expressDetail = (id) => {
    return request({
        url: api.expressDetail,
        method: 'get',
        params: {id},
    })
}

export const add = (data) => {
    return request({
        url: api.add,
        method: 'post',
        data: {form: data}
    })
}

export const tdsList = (userType) => {
    return request({
        url: api.tdsList,
        method: 'get',
        params: {userType},
    })
}

export const inventoryDetail = (id) => {
    return request({
        url: api.subinventory,
        method: 'get',
        params: {id},
    })
}

export const updateOrderExpress = (id, data) => {
    return request({
        url: api.updateOrderExpress,
        method: 'post',
        data: { id, form: data}
    })
}
