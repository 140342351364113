<template>
	<div class="psi-goods">
		<div class="my-title clearfix">
			<span class="">商品管理</span>
			<em @click="addClick()">添加新商品</em>
		</div>

		<el-form :inline="true" :model="search" class="demo-form-inline">
		  <el-form-item label="分类">
		    <el-select clearable v-model="search.category_id" placeholder="商品分类">
		      <el-option v-for="(item,index) in categoryList" :key="index" :label="item.name" :value="item.id"></el-option>
		    </el-select>
		  </el-form-item>
		  <el-form-item>
		    <el-button type="primary" @click="onSearch">查询</el-button>
		  </el-form-item>
		</el-form>
		<el-table :data="list" stripe>
			<el-table-column prop="goods_no" label="商品编码">
			</el-table-column>
			<el-table-column prop="goods_name" label="商品名称"  align="center">
			</el-table-column>
			<el-table-column prop="category.name" label="商品分类" align="center">
			</el-table-column>
			<el-table-column prop="spec" label="规格" align="center">
			</el-table-column>
			<el-table-column label="操作" width="200" align="center">
				<template slot-scope="scope">
					<el-button class="button-primary-mini" type="primary" size="mini" @click="editClick(scope.row)">编辑</el-button>
					<el-button class="button-warning-mini" type="danger" size="mini" @click="handleRemove(scope.row.id)">删除</el-button>
				</template>
			</el-table-column>
		</el-table>
		<!-- 分页 -->
		<div class="paging">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" background
				:current-page.sync="currentPage" :page-size="pagesize" layout="prev, pager, next, jumper" :total="total">
			</el-pagination>
		</div>
		<!-- 添加 -->
		<el-dialog :title="title" :visible.sync="dialogVisible" width="30%">
			<goodsAdd :goods="goods" @update="update"></goodsAdd>
		</el-dialog>
	</div>
</template>

<script>
	import * as Api from '@/api/psi'
	import goodsAdd from "./components/goods.vue"
	export default {
		components:{goodsAdd},
		data() {
			return {
				currentPage: 1,
				pagesize: 20,
				total: 0,
				goods: {},
				title: '',
				dialogVisible: false,
				list: [],
				search: {
					category_id: ''
				},
				categoryList: []
			}
		},
		created() {
			this.getPageData()
			this.getCategoryList()
		},
		methods: {
			onSearch(){
				this.currentPage == 1
				this.getPageData()
			},
			getCategoryList() {
			  const app = this
			  Api.category_list()
			  .then(result => {
			    app.categoryList = result.data.list
			  })
			},
			editClick(item){
				this.title = '编辑商品'
				this.goods = item
				this.dialogVisible = true
			},
			update(){
				this.getPageData()
				this.dialogVisible = false
			},
			// 获取页面数据
			getPageData() {
			  const app = this
			  Promise.all([app.getList()])
			    .then(() => {
			      this.dialogVisible = false
			    })
			},

			// 获取列表
			getList() {
			  const app = this
			  return new Promise((resolve, reject) => {
			    Api.goods_list({ page: app.currentPage, pagesize: app.pagesize, category_id: app.search.category_id })
			      .then(result => {
					  console.log(result)
			        app.list = result.data.list.data
					app.total = result.data.list.total
			        resolve(result)
			      })
			      .catch(err => reject(err))
			  })
			},

			addClick() {
				this.title = '新增商品'
				this.goods = {}
				this.dialogVisible = true
			},

			handleRemove(id) {
			  const app = this
			  this.$confirm('您确定要删除当前记录吗?', '提示', {
			  	confirmButtonText: '确定',
			  	cancelButtonText: '取消',
			  	type: 'warning'
			  }).then(() => {
			  	app.onRemove(id)
			  }).catch(() => {});
			},

			onRemove(id) {
			  const app = this
			  Api.goods_del({id: id})
			    .then(result => {
			      app.getPageData()
			    })
			},

			handleSizeChange(val) {
				console.log(`每页 ${val} 条`);
			},
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.currentPage = val
				this.getPageData()
			},
			handleClick(tab, event) {
				console.log(tab, event);
			}
		}
	}
</script>

<style lang="scss">
	
</style>
