<template>
	<div class="address-add">
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
			<el-form-item label="单号" prop="order_no">
				<el-input v-model="ruleForm.order_no" :disabled="true"></el-input>
			</el-form-item>
			<el-form-item label="日期" prop="ordertime">
				<el-date-picker
				  v-model="ruleForm.ordertime"
				  type="date"
				  value-format="yyyy-MM-dd"
				  placeholder="请选择订单日期">
				</el-date-picker>
			</el-form-item>
			<el-form-item :label="ruleForm.type == 1 ? '供应商' : '客户'" prop="customer_id">
				<el-select v-model="ruleForm.customer_id" placeholder="请选择">
				  <el-option
				    v-for="item in customerList"
				    :key="item.id"
				    :label="item.name"
				    :value="item.id">
				  </el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="金额" prop="amount">
				<el-input v-model="ruleForm.amount" :disabled="true"></el-input>
			</el-form-item>
			<el-form-item label="备注" prop="remark">
				<el-input v-model="ruleForm.remark" placeholder="请输入备注"></el-input>
			</el-form-item>
			<el-form-item label="" prop="">
				<el-button class="filter-item" style="margin-left: 10px;" type="primary" icon="el-icon-edit" @click="handleCreate">
				  添加
				</el-button>
			</el-form-item>
			<el-form-item label="明细" prop="details">
				<el-table :data="ruleForm.details" border fit highlight-current-row style="width: 100%;"
				   ref="detailTable">
				  <el-table-column label="商品" prop="goods_id" align="center">
				    <template slot-scope="{row, $index}">
				      <!-- <el-select v-model="row.goods_id" placeholder="请选择商品" style="width: 100%;">
				        <el-option v-for="(item,index) in goodsList" :key="item.id" :label="item.goods_name" :value="item.id" />
				      </el-select> -->
					  <el-cascader filterable v-model="row.gvalue" :options="goodsList" :show-all-levels="false" @change="(val) => goodsChange(val, row)"></el-cascader>
				    </template>
				  </el-table-column>
				  <el-table-column label="价格" prop="price" align="center">
				    <template slot-scope="{row, $index}">
				      <el-input v-model="row.price" class="edit-input" size="small" @blur="computeAmount" />
				    </template>
				  </el-table-column>
				  <el-table-column label="数量" prop="num" align="center">
				    <template slot-scope="{row, $index}">
				      <el-input v-model="row.num" type="number" class="edit-input" size="small" @blur="computeAmount" />
				    </template>
				  </el-table-column>
				  <el-table-column label="金额" prop="amount" align="center">
				  </el-table-column>

				  <el-table-column label="操作" align="center" class-name="small-padding fixed-width" fixed="right">
				    <template slot-scope="{row,$index}">
				      <el-button size="mini" type="danger" @click="handleDelete(row,$index)">
				        删除
				      </el-button>
				    </template>
				  </el-table-column>
				</el-table>
			</el-form-item>

			<el-form-item>
				<el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
	import * as Api from '@/api/psi'

	const defaultDetail = {
	  id: 0,
	  goods_id: '',
	  price: '',
	  num: 1,
	  amount: 0
	}

	export default {
		props:{
			order: Object
		},
		data() {
			return {
				ruleForm: {
					id: 0,
					type: 0,
					order_no: '',
					ordertime: '',
					customer_id: '',
					amount: 0,
					remark: '',
					details: []
				},
				rules: {
					ordertime: [{
							required: true,
							message: '请选择订单日期',
							trigger: 'change'
						}
					],
					customer_id: [{
							required: true,
							message: '请选择单位',
							trigger: 'change'
						}
					],
				},
				customerList: [],
				goodsList: []
			};
		},
		watch:{
			order(){
				this.initData()
			}
		},
		created() {
			this.initData()
			this.getGoodsList()
		},
		methods: {
			initData(){
				this.ruleForm = this.order
				if(!this.ruleForm.id){
					this.ruleForm.id = 0
				}
				this.ruleForm.details.forEach(e=>{
					e.gvalue = [e.goods.category_id, e.goods_id]
				})
				this.getCustomerList()
				this.$forceUpdate()
			},
			getCustomerList(){
				Api.customer_list({ type: this.ruleForm.type, page: 1, pagesize: 9999 })
                    .then(result => {
                        this.customerList = result.data.list.data
                    })
			},
			getGoodsList(){
				Api.goodstree_list().then(result => {
                      let list = result.data.list
                      let goodsList = []
                      list.forEach(e=>{
                          let children = []
                          e.goods.forEach(c=>{
                              children.push({
                                  value: c.id,
                                  label: c.goods_name + '[' + c.goods_no + ']'
                              })
                          })
                          goodsList.push({
                              value: e.id,
                              label: e.name,
                              children: children
                          })
                      })
                      this.goodsList = goodsList
                })
			},
			submitForm(formName) {
				let app = this
				this.$refs[formName].validate((valid) => {
					if (valid) {
						console.log(app.ruleForm)
						Api.order_save(app.ruleForm)
                            .then(result => {
                                app.$message.success(result.message)
                                app.$emit('update')
                            })
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			handleCreate() {
                let detail = Object.assign({}, defaultDetail)
                this.ruleForm.details.unshift(detail)
			},
            handleDelete(row, index) {
                this.ruleForm.details.splice(index, 1)
            },
            computeAmount() {
                let amount = 0
                this.ruleForm.details.forEach((item) => {
                    if (item.price && item.num) {
                        item.amount = item.price * item.num
                        if (item.amount) {
                            amount += item.amount
                        }
                    }
                })
                this.ruleForm.amount = amount
            },
			goodsChange(val, row){
				if(val && val.length > 1){
					row.goods_id = val[1]
				}
			}
		}
	}
</script>

<style>
</style>
