<template>
    <div class="cart my-import">

        <!--导入失败的列表-->
        <div class="import-title clearfix">
            <h3 class="">物流单上传</h3>
            <el-button type="primary" @click="showCList()" class="btn">C端待上传明细</el-button>
            <el-button type="primary" @click="showTdsList()" class="btn">B端待上传明细</el-button>
            <el-button type="primary" @click="addClick()" icon="el-icon-top" class="btn">上传</el-button>
        </div>
        <el-table class="my-import-table" :data="list" stripe style="width: 100%; margin-bottom: 20px">
            <el-table-column prop="update_time" label="上传时间">
            </el-table-column>
            <el-table-column fixed="right" label="操作" align="center">
                <template slot-scope="scope">
                    <el-button size="mini" type="primary" plain @click="detail(scope.row)">查看详情</el-button>
                </template>
            </el-table-column>
        </el-table>
				<!-- 上传 -->
        <el-dialog title="上传" :visible.sync="dialogVisible" width="30%">
            <el-form ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="下载导入模板" prop="name">
                    <a
                        href="https://yuweiyeke.oss-cn-zhangjiakou.aliyuncs.com/static/files/%E9%9D%92%E5%8D%A1%E7%89%A9%E6%B5%81%E6%A8%A1%E6%9D%BF%20v1.xlsx">下载</a>
                </el-form-item>
                <el-form-item label="选择导入文件" prop="name">
                    <el-upload :action="uploadUrl" :auto-upload="true" :limit="1" ref="upload" :headers="headers"
                               :on-success="uploadSucc">
                        <el-button size="small" type="primary">点击上传</el-button>
                        <div slot="tip" class="el-upload__tip">只能上传Excel文件</div>
                    </el-upload>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="handleImport()">确 定</el-button>
            </div>
        </el-dialog>
				<!-- 查看详情 -->
        <el-dialog title="明细" :visible.sync="dialogDetailVisible" width="80%">
            <el-table class="my-import-table" :data="expressDetails.filter(e=>e.status == 1)" stripe max-height="500px">
                <el-table-column prop="tds_no" label="XS单号">
                </el-table-column>
                <el-table-column prop="warehouse_name" label="发货仓库">
                </el-table-column>
                <el-table-column prop="express_name" label="物流公司">
                </el-table-column>
                <el-table-column prop="express_no" label="物流单号">
                </el-table-column>
                <el-table-column prop="express_phone" label="物流电话">
                </el-table-column>
                <el-table-column prop="express_receiver" label="收货人姓名">
                </el-table-column>
                <el-table-column prop="express_number" label="发货件数">
                </el-table-column>
                <el-table-column prop="express_remark" label="备注">
                </el-table-column>
                <el-table-column prop="status" label="处理结果">
                    <template slot-scope="scope">
                        <span v-if="scope.row.status == 1">成功</span>
                        <span v-else style="color: red">失败</span>
                    </template>
                </el-table-column>
                <el-table-column prop="err_msg" label="错误信息">
                </el-table-column>
            </el-table>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogDetailVisible = false">关闭</el-button>
            </div>
        </el-dialog>
				<!-- C端待上传明细 -->
        <el-dialog title="TDS明细（B端待上传物流信息）" :visible.sync="dialogTdsVisible" width="50%">
            <el-table class="my-import-table" :data="tdsList" max-height="500px" stripe>
                <el-table-column prop="tds_no" label="XS单号">
                </el-table-column>
                <el-table-column prop="warehouse_name" label="发货仓库">
                </el-table-column>
            </el-table>
            <div slot="footer" class="dialog-footer">
                <el-button @click="exportTdsList">导出</el-button>
                <el-button @click="dialogTdsVisible = false">关闭</el-button>
            </div>
        </el-dialog>
				<!-- B端待上传明细 -->
				<el-dialog title="TDS明细（C端待上传物流信息）" :visible.sync="dialogCVisible" width="50%">
				    <el-table class="my-import-table" :data="tdsList" max-height="500px" stripe>
				        <el-table-column prop="tds_no" label="XS单号">
				        </el-table-column>
				        <el-table-column prop="warehouse_name" label="发货仓库">
				        </el-table-column>
								<el-table-column label="操作">
									<template slot-scope="scope">
										<el-button @click="deliveryClick(scope.row)" type="text" size="small">发货</el-button>
									</template>
								</el-table-column>
				    </el-table>
				    <div slot="footer" class="dialog-footer">
				        <el-button @click="exportCList">导出</el-button>
				        <el-button @click="dialogCVisible = false">关闭</el-button>
				    </div>
				</el-dialog>
				<!-- 发货 -->
				<el-dialog title="发货" :visible.sync="dialogDeliveryVisible" width="500px">
				  <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
				    <el-form-item label="物流公司" prop="express_id">
				      <el-select v-model="ruleForm.express_id" @change="onExpressChange" placeholder="请选择物流公司">
				        <el-option v-for="(item, index) in expressList"
                           :key="index"
                           :value="item.express_id" :label="item.express_name"></el-option>
				      </el-select>
				    </el-form-item>
						<el-form-item label="物流单号" prop="express_no">
							<el-input v-model="ruleForm.express_no"></el-input>
						</el-form-item>
				    <el-form-item>
				      <el-button type="primary" @click="submitForm('ruleForm')">确定</el-button>
				      <el-button @click="resetForm('ruleForm')">重置</el-button>
				    </el-form-item>
				  </el-form>
				</el-dialog>
    </div>
</template>

<script>
import * as FawApi from '@/api/faw'
import * as ExpressApi from '@/api/express'
import {updateOrderExpress} from "../../api/faw";

export default {
    components: {},
    data() {
      return {
        expressList: [],
        ruleForm: {
          express_id: '',
          express_name: '',
          express_no: ''
        },
        rules: {
          express_id: [
            {required: true, message: '请选择物流公司', trigger: 'change'}
          ],
          express_no: [
            {required: true, message: '请输入物流单号', trigger: 'blur'}
          ]
        },
        uploadUrl: process.env.VUE_APP_BASE_API + 'upload/image',
        headers: {
          "Access-Token": localStorage.getItem('token')
        },
        total: 0,
        list: [],
        dialogVisible: false,
        fileId: 0,
        importForm: {
          fileId: '',
        },
        dialogDetailVisible: false,
        expressDetails: [],
        dialogTdsVisible: false,
        dialogCVisible: false,
        dialogDeliveryVisible: false,
        tdsList: [],
        record: {}
      };
    },
    created() {
        if (!localStorage.getItem('userId')) {
            this.$message.error('请先登录')
            this.$router.push("/login")
        }
        this.getList()
        this.getExpressList()
    },
    methods: {
      onExpressChange(e) {
        this.ruleForm.express_name = this.expressList.find(item => item.express_id == e).express_name
      },
      // 获取物流公司列表
      getExpressList() {
        this.isLoading = true
        ExpressApi.list()
            .then(result => {
              this.expressList = result.data.list
            })
            .finally(() => {
              this.isLoading = false
            })
      },
				submitForm(formName) {
					this.$refs[formName].validate((valid) => {
						if (valid) {
              console.log(this.record)
              FawApi.updateOrderExpress(this.record.id, this.ruleForm).then(() => {
                this.$message.success('发货成功')
                this.showCList()
                this.dialogDeliveryVisible = false
              })
						} else {
							console.log('error submit!!');
							return false;
						}
					});
				},
				resetForm(formName) {
					this.$refs[formName].resetFields();
				},
				// 发货
				deliveryClick(row){
          this.record = row
          this.ruleForm = {
            express_id: '',
            express_name: '',
            express_no: ''
          }
					this.dialogDeliveryVisible = true
				},
        getList() {
            FawApi.list({type: 'express'})
                .then(result => {
                    this.list = result.data.list.data
                })
        },
        addClick() {
            this.dialogVisible = true
            this.$refs.upload.clearFiles()
        },
        showTdsList() {
            FawApi.tdsList('B').then(result => {
                this.tdsList = result.data.list
            })
            this.dialogTdsVisible = true
        },
				showCList() {
				    FawApi.tdsList('C').then(result => {
				        this.tdsList = result.data.list
				    })
				    this.dialogCVisible = true
				},
        uploadSucc(response, file, fileList) {
            let app = this
            app.importForm.fileId = fileList[0].response.data.fileInfo.file_id

        },
        handleImport() {
            let app = this
            if (!app.importForm.fileId) {
                app.$message.error('请选择导入文件')
                return
            }
            FawApi.add({ file_id: app.importForm.fileId, type: 'express' })
                .then(() => {
                    app.$message.success('导入成功')
                    app.dialogVisible = false
                    app.importForm.fileId = ''
                    app.getList()
                })
        },
        detail(row) {
            this.expressDetails = []
            FawApi.expressDetail(row.id)
                .then(result => {
                    this.expressDetails = result.data.list
                    this.dialogDetailVisible = true
                })
        },
				exportCList() {
				    const app = this
				    let filename = '物流待上传列表'

				    import('@/vendor/Export2Excel').then(excel => {
				        const tHeader = ['XS单号', '发货仓库']
				        const filterVal = ['tds_no', 'warehouse_name']
				        const data = this.formatJson(filterVal, app.tdsList)
				        excel.export_json_to_excel({
				            header: tHeader,
				            data,
				            filename: filename,
				            autoWidth: true,
				            bookType: 'xlsx'
				        })
				    })
				},
        exportTdsList() {
            const app = this
            let filename = '物流待上传列表'

            import('@/vendor/Export2Excel').then(excel => {
                const tHeader = ['XS单号', '发货仓库']
                const filterVal = ['tds_no', 'warehouse_name']
                const data = this.formatJson(filterVal, app.tdsList)
                excel.export_json_to_excel({
                    header: tHeader,
                    data,
                    filename: filename,
                    autoWidth: true,
                    bookType: 'xlsx'
                })
            })
        },
        formatJson(filterVal, jsonData) {
            let _this = this
            return jsonData.map(v => filterVal.map(j => {
                return v[j]
            }))
        },
    }

}
</script>

<style lang="scss">
@import "../../assets/css/cart.scss";
@import "../../assets/css/my-import.scss";
</style>
