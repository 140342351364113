<template>
    <div class="cart my-import">

        <div class="import-title clearfix">
            <h3 class="">库存管理</h3>
            <el-button type="primary" @click="addClick()" icon="el-icon-top" class="btn">上传</el-button>
        </div>
        <el-table class="my-import-table" :data="list" stripe style="width: 100%; margin-bottom: 20px">
            <el-table-column prop="update_time" label="上传时间">
            </el-table-column>
            <el-table-column prop="updated" label="上传数量">
            </el-table-column>
            <el-table-column fixed="right" label="操作" align="center">
                <template slot-scope="scope">
                    <el-button size="mini" type="primary" plain @click="detail(scope.row)">查看详情</el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-dialog title="上传" :visible.sync="dialogVisible" width="30%">
            <el-form ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="下载导入模板" prop="name">
                    <a href="https://yuweiyeke.oss-cn-zhangjiakou.aliyuncs.com/static/files/%E7%BB%8F%E9%94%80%E5%95%86%E5%BA%93%E5%AD%98%E4%B8%8A%E4%BC%A0%E6%A8%A1%E6%9D%BF.xlsx">下载</a>
                </el-form-item>
                <el-form-item label="选择导入文件" prop="name">
                    <el-upload :action="uploadUrl" :auto-upload="true" :limit="1" ref="upload" :headers="headers"
                               :on-success="uploadSucc">
                        <el-button size="small" type="primary">点击上传</el-button>
                        <div slot="tip" class="el-upload__tip">只能上传Excel文件</div>
                    </el-upload>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="handleImport()">确 定</el-button>
            </div>
        </el-dialog>

        <el-dialog title="明细" :visible.sync="dialogDetailVisible" width="80%">
            <el-table class="my-import-table" :data="inventoryDetails" stripe style="width: 100%; margin-bottom: 20px">
                <el-table-column prop="buy_code" label="订货备件代码">
                </el-table-column>
                <el-table-column prop="goods_name" label="备件名称">
                </el-table-column>
                <el-table-column prop="goods_num" label="数量">
                </el-table-column>
                <el-table-column prop="status" label="处理结果">
                    <template slot-scope="scope">
                        <span v-if="scope.row.status == 1">成功</span>
                        <span v-else style="color: red">失败</span>
                    </template>
                </el-table-column>
                <el-table-column prop="err_msg" label="错误信息">
                </el-table-column>
            </el-table>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogDetailVisible = false">关闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import * as FawApi from '@/api/faw'
import {inventoryDetail} from "../../api/faw";

export default {
    components: {},
    data() {
        return {
            uploadUrl: process.env.VUE_APP_BASE_API + 'upload/image',
            headers: {
                "Access-Token": localStorage.getItem('token')
            },
            total: 0,
            list: [],
            dialogVisible: false,
            fileId: 0,
            importForm: {
                fileId: '',
            },
            dialogDetailVisible: false,
            inventoryDetails: [],
            dialogTdsVisible: false,
        };
    },
    created() {
        if (!localStorage.getItem('userId')) {
            this.$message.error('请先登录')
            this.$router.push("/login")
        }
        this.getList()
    },
    methods: {
        getList() {
            FawApi.list({type: 'subinventory'})
                .then(result => {
                    this.list = result.data.list.data
                })
        },
        addClick() {
            this.dialogVisible = true
            this.$refs.upload.clearFiles()
        },
        uploadSucc(response, file, fileList) {
            let app = this
            app.importForm.fileId = fileList[0].response.data.fileInfo.file_id

        },
        handleImport() {
            let app = this
            if (!app.importForm.fileId) {
                app.$message.error('请选择导入文件')
                return
            }
            FawApi.add({ file_id: app.importForm.fileId, type: 'subinventory' })
                .then(() => {
                    app.$message.success('导入成功')
                    app.dialogVisible = false
                    app.importForm.fileId = ''
                    app.getList()
                })
        },
        detail(row) {
            this.inventoryDetails = []
            FawApi.inventoryDetail(row.id)
                .then(result => {
                    this.inventoryDetails = result.data.list
                    this.dialogDetailVisible = true
                })
        },
    }

}
</script>

<style lang="scss">
@import "../../assets/css/cart.scss";
@import "../../assets/css/my-import.scss";
</style>
