<template>
	<div class="address-add">
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
			<el-form-item label="名称" prop="name">
				<el-input v-model="ruleForm.name" placeholder="请输入名称"></el-input>
			</el-form-item>
			<el-form-item label="电话" prop="phone">
				<el-input v-model="ruleForm.phone" placeholder="请输入电话"></el-input>
			</el-form-item>
			<el-form-item label="联系人" prop="contact">
				<el-input v-model="ruleForm.contact" placeholder="请输入联系人"></el-input>
			</el-form-item>
			<el-form-item label="地址" prop="address">
				<el-input v-model="ruleForm.address" placeholder="请输入地址"></el-input>
			</el-form-item>

			<el-form-item>
				<el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
				<el-button @click="resetForm('ruleForm')">重置</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
	import * as Api from '@/api/psi'
	export default {
		props:{
			customer: Object
		},
		data() {
			return {
				ruleForm: {
					id: 0,
					type: 0,
					name: '',
					phone: '',
					contact: '',
					address: ''
				},
				rules: {
					name: [{
							required: true,
							message: '请输入名称',
							trigger: 'blur'
						}
					],
					phone: [{
							required: true,
							message: '请输入联系电话',
							trigger: 'blur'
						}
					],
				}
			};
		},
		watch:{
			customer(){
				this.initData()
			}
		},
		created() {
			this.initData()
		},
		methods: {
			initData(){
				this.ruleForm = this.customer
				if(!this.ruleForm.id){
					this.ruleForm.id = 0
				}
				this.$forceUpdate()
			},
			submitForm(formName) {
				let app = this
				this.$refs[formName].validate((valid) => {
					if (valid) {
						console.log(app.ruleForm)
						Api.customer_save(app.ruleForm)
						  .then(result => {
						    app.$message.success(result.message)
							app.$emit('update')
						  })
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			}
		}
	}
</script>

<style>
</style>
