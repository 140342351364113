<template>
	<div class="my-bankcard">
		<div class="my-title clearfix">
			<span class="">我的银行卡</span>
			<em @click="editClick()">添加银行卡</em>
		</div>
		<div class="bankcard-con clearfix">
			<ul>
				<li v-for="(item,index) in addresList" :key="item.id">
					<div class="top clearfix">
						<span class="bank-logo"
							:class="{'bank-jiaotong':item.card==1,'bank-jianshe':item.card==2,'bank-gongshang':item.card==3}"></span>
						<span class="number">尾号{{item.number}}</span>
						<em v-if="item.state === 1">信用卡</em>
						<em v-if="item.state === 2" class="blue">储蓄卡</em>
					</div>
					<div class="bankcard-txt">
						<p>持卡人姓名:*华</p>
						<p>手机号：186****0123</p>
					</div>
					<div class="edit" @click.stop="editClick()">编辑</div>
					<div class="edit" @click.stop="deleteClick(index)">删除</div>
				</li>
			</ul>
		</div>
		<!-- 添加银行卡 -->
		<el-dialog title="添加银行卡" :visible.sync="dialogVisible" width="30%">
			<el-form ref="addForm" :model="form" :rules="rules" label-width="100px" class="add-card">
				<el-form-item label="真实姓名：">
					*华
				</el-form-item>
				<el-form-item label="身份证：">
					3****************0
				</el-form-item>
				<el-form-item prop="bankcard" label="银行卡号：" class="bankcard">
					<el-input type="text" placeholder="请输入银行卡号" autocomplete="off" autofocus="true"
						v-model="form.bankcard" />
					<el-popover placement="top-start" title="支持银行" width="200" trigger="hover" class="tips"
						content="交通银行、中国建设银行、中国工商银行">
						<el-button slot="reference">支持银行</el-button>
					</el-popover>

				</el-form-item>
				<el-form-item prop="telephone" label="手机号码：">
					<el-input type="text" placeholder="请输入手机号" autocomplete="off" autofocus="true"
						v-model="form.telephone" />
				</el-form-item>

				<el-form-item prop="smscode" class="code" label="验证码：">
					<el-input v-model="form.smscode" placeholder="请输入验证码"></el-input>
					<el-button class="btn-orange send-code" type="warning" size="mini" :disabled='isDisabled'
						@click="sendCode">
						{{buttonText}}
					</el-button>
				</el-form-item>

				<el-form-item>《咔咔商城支付服务协议》</el-form-item>

				<el-form-item>
					<el-button class="btn-orange" type="warning" v-on:click="onSubmit('addForm')">同意协议并确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			// <!--验证手机号是否合法-->
			let checkTel = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请输入手机号码'))
				} else if (!this.checkMobile(value)) {
					callback(new Error('请输入正确的手机号码'))
				} else {
					callback()
				}
			}
			return {
				dialogVisible: false,
				addresList: [{
					id: 1,
					number: "3055",
					state: 1,
					card: 1
				}, {
					id: 2,
					number: "1820",
					state: 2,
					card: 2
				}, {
					id: 3,
					number: "1820",
					state: 2,
					card: 3
				}, {
					id: 4,
					number: "1820",
					state: 2,
					card: 3
				}],
				form: {
					telephone: '',
					smscode: ''
				},
				buttonText: '发送验证码',
				flag: true,
				isDisabled: false, // 是否禁止点击发送验证码按钮
				rules: {
					bankcard: [{
						required: true,
						message: "请输入银行卡号",
						trigger: 'change'
					}],
					telephone: [{
						required: true,
						validator: checkTel,
						trigger: 'change'
					}],
					smscode: [{
						required: true,
						message: "请输入验证码",
						trigger: 'change'
					}]
				},

			}
		},
		methods: {
			// 验证手机号
			checkMobile(str) {
				let re = /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[0-9])\d{8}$/
				if (re.test(str)) {
					return true;
				} else {
					return false;
				}
			},
			sendCode() {
				let tel = this.form.telephone
				if (this.checkMobile(tel)) {
					let time = 60
					this.buttonText = '已发送'
					this.isDisabled = true
					if (this.flag) {
						this.flag = false;
						let timer = setInterval(() => {
							time--;
							this.buttonText = time + ' 秒'
							if (time === 0) {
								clearInterval(timer);
								this.buttonText = '重新获取'
								this.isDisabled = false
								this.flag = true;
							}
						}, 1000)
					}
				} else {
					this.$message.error('请输入手机号');
				}
			},
			onSubmit(formName) {
				// 为表单绑定验证功能
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.$message({
							message: '添加成功！',
							type: 'success'
						});
					} else {
						return false;
					}
				});
			},
			editClick() {
				this.dialogVisible = true
			},
			deleteClick(index) {
				this.$confirm('确定要删除该行吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.addresList.splice(index, 1)
					this.$message({
						type: 'success',
						message: '删除成功!'
					});
				}).catch(() => {});
			}
		}
	}
</script>

<style lang="scss">
	@import "../../assets/css/my-wallet.scss"
</style>
